import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import CourseTopic from "../components/CourseTopic";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import TitleSection from "../components/TitleSection";
import Nav from "../components/Nav";
import Preloader from "../components/Preloader";

const CourseTopics = () => {
  const [co, setCo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ev, setEv] = useState("");

  const { cid, tid } = useParams();

  useEffect(() => {
    getCo();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    search(co);
  }, [ev]);

  const getCo = async () => {
    setLoading(true);
    let { data } = await axios.get(
      `${process.env.REACT_APP_API_KEY}/topics/${cid}/${tid}`
    );
    setCo(data);
    setLoading(false);
  };

  const search = (data) => {
    return data.filter((item) =>
      item.title.toLowerCase().includes(ev.toLowerCase())
    );
  };

  let d = [];
  d = cid.split("_");

  return (
    <>
      <div className="page-wrapper">
        {/* Preloader */}
        <div className="preloader1" />
        {/* Main Header*/}
        <Nav />
        {/* End Main Header */}
        <TitleSection title="Courses" setEv={setEv} />
        {loading ? (
          <Preloader />
        ) : (
          <CourseTopic co={search(co)} d={d} cid={cid} tid={tid} />
        )}
        <Footer />
      </div>
      {/*End pagewrapper*/}
      {/*Scroll to top*/}
      <ScrollToTop />
    </>
  );
};

export default CourseTopics;
