import React from "react";

const VideoPage = ({ co }) => {
  return (
    <>
      <section className="topics-section pt-5">
        <div
          className="patern-layer-one paroller"
          data-paroller-factor="0.40"
          data-paroller-factor-lg="0.20"
          data-paroller-type="foreground"
          data-paroller-direction="vertical"
          style={{ backgroundImage: "url(/images/icons/icon-1.png)" }}
        />
        <div
          className="patern-layer-two paroller"
          data-paroller-factor="0.40"
          data-paroller-factor-lg="-0.20"
          data-paroller-type="foreground"
          data-paroller-direction="vertical"
          style={{ backgroundImage: "url(/images/icons/icon-2.png)" }}
        />
        <div className="auto-container">
          <div className="sec-title centered">
            <h5 className="fw-light text-black">
              <span className="fw-bold text-black">Subject: </span>
              {co?.name}
            </h5>
            <h5 className="fw-light text-black">
              <span className="fw-bold text-black">Topic Title: </span>
              {co?.topic_title}
            </h5>
            <h5 className="fw-light text-black">
              <span className="fw-bold text-black">Video Title: </span>
              {co?.title}
            </h5>
            <ul className="row justify-content-around mt-3">
              {co?.videos?.map((content, index) => (
                <div
                  className="col-lg-3 col-md-4 col-sm-6 col-6 buttons-box pb-4"
                  key={index}
                >
                  <p className="m-1 text-dark">LINK {index + 1}</p>
                  <a
                    href={content.msg}
                    className="theme-btn btn-style-two bg-info shadow shadow-lg"
                  >
                    <span className="txt">
                      {content.vid_format}
                      <i className="fa fa-angle-right" />
                    </span>
                  </a>
                </div>
              ))}
            </ul>

            <h6 className="fw-light text-black">
              *If the 1st Link Doesn't Work, Try Other Links Too.
            </h6>
          </div>
        </div>
      </section>
    </>
  );
};

export default VideoPage;
