import React from "react";
import Pagination from "./Pagination";
import { useNavigate } from "react-router-dom";

const CoachingCourses = ({
  id,
  co,
  d,
  count,
  limit,
  page,
  setCurrentPage,
  tid,
}) => {
  const navigate = useNavigate();
  return (
    <div className="sidebar-page-container">
      <div
        className="patern-layer-one paroller"
        data-paroller-factor="0.40"
        data-paroller-factor-lg="0.20"
        data-paroller-type="foreground"
        data-paroller-direction="vertical"
        style={{ backgroundImage: "url(/images/icons/icon-1.png)" }}
      />
      <div
        className="patern-layer-two paroller"
        data-paroller-factor="0.40"
        data-paroller-factor-lg="-0.20"
        data-paroller-type="foreground"
        data-paroller-direction="vertical"
        style={{ backgroundImage: "url(/images/icons/icon-2.png)" }}
      />
      <div className="circle-one" />
      <div className="circle-two" />
      <div className="auto-container">
        <div className="row clearfix">
          {/* Content Side */}
          <div className="content-side col-lg-12 col-md-12 col-sm-12">
            <div className="our-courses">
              {/* Options View */}
              <div className="options-view">
                <div className="clearfix">
                  <div className="pull-left">
                    <h3 className="text-capitalize">All Courses</h3>
                  </div>
                  <div className="pull-right clearfix">
                    {/* List View */}
                    <ul className="list-view">
                      <li className="active">
                        <a>
                          <span className="icon flaticon-grid" />
                        </a>
                      </li>
                      <li>
                        <a>
                          <span className="icon flaticon-list-1" />
                        </a>
                      </li>
                    </ul>
                    {/* Type Form */}
                    <div className="type-form">
                      <form method="post" action="index.html">
                        {/* Form Group */}
                        <div className="form-group">
                          <select className="custom-select-box">
                            <option>Newest</option>
                            <option>Old</option>
                          </select>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row clearfix">
                {co &&
                  co.map((datas, index) => {
                    return (
                      <div
                        className="cource-block-two col-lg-3 col-md-4 col-sm-6 d-flex"
                        key={datas?._id}
                      >
                        <button
                          type="button"
                          onClick={() =>
                            datas?.extra_info?.combo_ids?.length > 0 &&
                            tid !== datas?._id
                              ? navigate(`/combo/${id}/${datas._id}`)
                              : navigate(`/course/${id}/${datas._id}
                              `)
                          }
                        >
                          <div className="inner-box w-100 h-100 d-flex flex-column shadow">
                            <div className="image">
                              <img
                                src="https://astroncollege.org/wp-content/uploads/2020/01/courses.jpg"
                                alt="pic"
                              />
                            </div>
                            <div className="lower-content d-flex flex-column h-100 justify-content-between">
                              <h6 className="fw-medium">
                                {datas.title || datas.name}
                              </h6>
                              <div className="text">
                                To browse the topics of this course click on
                                this card.
                              </div>
                              <div className="clearfix">
                                <div className="pull-left">
                                  <div className="students m-0">
                                    {datas?.extra_info?.combo_ids?.length > 0 &&
                                    tid !== datas?._id ? (
                                      <div className="hours1 text-bg-dark text-white">
                                        Combo
                                      </div>
                                    ) : (
                                      <div className="mt-2">
                                        {datas?.topics} Lectures
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="pull-right">
                                  <div className="hours1 text-white">
                                    Read...
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </button>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          {/* Sidebar Side */}
          {/* <Sidebar /> */}
        </div>
        {/* Post Share Options */}
        <Pagination
          total={count}
          limit={limit}
          page={page}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default CoachingCourses;
