import React from "react";
import { Link } from "react-router-dom";

const Hometitle = ({ title, desc, showBtn }) => {
  return (
    <section className="page-title pt-3 pt-md-4">
      <div className="auto-container">
        <div className="py-3 py-md-4 py-lg-5">
          <h1 className="text-capitalize">{title || "Home"}</h1>
          <h6 className="title_desc">
            {desc || (
              <span>
                Access to quality online learning is an important step in
                helping people future-proof their skills and seek new
                opportunities for growth and development.
              </span>
            )}
          </h6>
        </div>
      </div>
      {showBtn && (
        <div className="buttons-box pb-4">
          <Link to="/coaching" className="theme-btn btn-style-two">
            <span className="txt">
              Explore All Coachings <i className="fa fa-angle-right" />
            </span>
          </Link>
        </div>
      )}
    </section>
  );
};

export default Hometitle;
