import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import Preloader from "../components/Preloader";
import { encode } from "../components/vignere/vigenereReact";
import Hometitle from "../components/Hometitle";
import VideoPage from "../components/VideoPage";

const Meow3 = () => {
  const [co, setCo] = useState([]);
  const [count, setCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [ev, setEv] = useState("");

  const LIMIT = 12;
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  useEffect(() => {
    getCo();
  }, []);

  const getCo = async () => {
    setLoading(true);
    const encoded_id = encode("Bsbshs key to encode telegram files ids", id);
    let { data } = await axios.get(
      `${process.env.REACT_APP_API_KEY}/get_video/${encoded_id}`
    );
    setCo(data);
    setLoading(false);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="preloader1" />
        <Nav />
        <Hometitle title="Watch Videos" desc="Watch Videos Here" />
        {loading ? <Preloader /> : <VideoPage co={co} />}

        <Footer />
      </div>
      {/*End pagewrapper*/}
      {/*Scroll to top*/}
      <ScrollToTop />
    </>
  );
};

export default Meow3;
