import React from "react";
import { Buffer } from "buffer";

const unescape = (str) => {
  return (str + "===".slice((str.length + 3) % 4))
    .replace(/-/g, "+")
    .replace(/_/g, "/");
};

const escape = (str) => {
  return str.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
};

const b_encode = (str, encoding) => {
  return escape(Buffer.from(str, encoding || "utf8").toString("base64"));
};

const b_decode = (str, encoding) => {
  return Buffer.from(unescape(str), "base64").toString(encoding || "utf8");
};

function encode(key, clear) {
  const enc = [];
  for (let i = 0; i < clear.length; i++) {
    const key_c = key[i % key.length];
    const enc_c = String.fromCharCode(
      (clear.charCodeAt(i) + key_c.charCodeAt(0)) % 256
    );
    enc.push(enc_c);
  }
  return b_encode(Buffer.from(enc.join("")));
}

function decode(key, enc) {
  const dec = [];
  enc = b_decode(enc).toString();
  for (let i = 0; i < enc.length; i++) {
    const key_c = key[i % key.length];
    const dec_c = String.fromCharCode(
      (256 + enc.charCodeAt(i) - key_c.charCodeAt(0)) % 256
    );
    dec.push(dec_c);
  }
  return dec.join("");
}

function link_encode(key, link) {
  const parts = link.split("/");
  const _id = parts[parts.length - 1];
  const enc_id = encode(key, _id);
  parts[parts.length - 1] = enc_id;
  const enc_link = parts.join("/");
  return enc_link;
}

function link_decode(key, enc_link) {
  const parts = enc_link.split("/");
  const enc_id = parts[parts.length - 1];
  const dec_id = decode(key, enc_id);
  parts[parts.length - 1] = dec_id;
  const dec_link = parts.join("/");
  return dec_link;
}

export { link_encode as encode, link_decode as decode };
