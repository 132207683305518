// Meow4.js

import React, { useState, useEffect } from "react";

function Meow4() {
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    fetch("http://localhost:5000/api/get-env")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setKey("");
        setValue("");
      })
      .catch((error) => console.error(error));
  }, []);

  const handleUpdate = async () => {
    try {
      const response = await fetch("http://localhost:5000/api/update-env", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, value }),
      });

      if (response.status === 200) {
        setMessage("Environment variable updated successfully");
      } else {
        setMessage("Error: Invalid input");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h1>Update Environment Variables</h1>
      <div>
        <input
          type="text"
          placeholder="Variable Key"
          value={key}
          onChange={(e) => setKey(e.target.value)}
        />
        <input
          type="text"
          placeholder="Variable Value"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <button onClick={handleUpdate}>Update Variable</button>
      </div>
      <p>{message}</p>
    </div>
  );
}

export default Meow4;
