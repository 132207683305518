import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import Preloader1 from "../components/admin/Preloader1";

const EnvVar = () => {
  const [loading, setLoading] = useState();
  const [batch, setBatch] = useState([]);
  const [error, setError] = useState();
  const [key2, setKey2] = useState("");
  const [key1, setKey1] = useState("");
  const [value, setValue] = useState("");
  const [show, setShow] = useState(false);
  const [delShow, setDelShow] = useState(false);
  const [delId, setDelId] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("logged_in");
    if (!isLoggedIn) {
      navigate("/signin");
    }
  });

  const handleClose = () => {
    setShow(false);
    setDelShow(false);
  };

  const handleDelShow = (id) => {
    console.log(id);
    setDelShow(true);
    setKey1(id);
    setDelId(id);
  };

  const handleEdit = (key0, value) => {
    console.log(key0, value);
    setKey1(key0);
    setKey2(key0);
    setValue(value);
    setShow(true);
  };

  useEffect(() => {
    getCoach();
  }, []);

  const getCoach = async () => {
    setLoading(true);
    let { data } = await axios.get(
      `${process.env.REACT_APP_API_KEY}/api/get-env`
    );
    setBatch(data);
    console.log(data);
    setLoading(false);
  };

  let i = 1;

  const saveChanges = async (e) => {
    e.preventDefault();
    console.log(key1, key2, value);

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_KEY}/api/update-env`,
        {
          key: key1,
          key_new: key2,
          value: value !== undefined ? value : "",
        }
      );

      if (response.status !== 200) {
        setError("Error");
      } else {
        handleClose();
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Data Edited Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        getCoach();
      }
    } catch (error) {
      console.error("Error sending data:", error.response.data);
      setError("Error Occurred");
    }
  };

  const delEnvs = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_KEY}/delEnv/${delId}`
      );

      if (!response.status === 200) {
        setError("Error");
      }

      if (response.status === 200) {
        handleClose();
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Batch Deleted Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        getCoach();
      }
    } catch (error) {
      console.error("Error sending data:", error.response.data);
      setError("Error Occured");
    }
  };

  return (
    <>
      <form className="card">
        <h3 className="text-center m-3">Environment Variables</h3>
        {error && (
          <div className="text-white text-bg-success p-1 mb-2 rounded-5 text-center">
            {error}
          </div>
        )}
        <div className="mb-5 mx-2 mx-auto">
          {loading ? (
            <Preloader1 />
          ) : (
            <>
              <table className="table table-striped table-bordered table-responsive">
                <thead className="text-center">
                  <tr>
                    <th scope="col">S.I</th>
                    <th scope="col">Key</th>
                    <th scope="col">Value</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(batch).map((key) => (
                    <tr key={key}>
                      <td className="text-center">{i++}</td>
                      <td>{key}</td>
                      <td>{batch[key]}</td>
                      <td>
                        <div className="d-flex justify-content-center gap-1 w-100 h-100">
                          <Link
                            type="button"
                            rel="tooltip"
                            className="btn btn-success"
                            onClick={() => handleEdit(key, batch[key])}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-pencil-square"
                              viewBox="0 0 16 16"
                            >
                              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                              <path
                                fillRule="evenodd"
                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                              />
                            </svg>
                          </Link>
                          <Link
                            type="button"
                            rel="tooltip"
                            className="btn btn-danger"
                            onClick={() => handleDelShow(key)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-trash3-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                            </svg>
                          </Link>
                        </div>
                      </td>
                      <Modal show={show} onHide={handleClose} centered>
                        <Modal.Header closeButton>
                          <Modal.Title>Edit Batch</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <form>
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputBatch"
                                className="form-label"
                              >
                                Key
                              </label>
                              <input
                                type="text"
                                className="form-control text-black"
                                id="exampleInputBatch"
                                value={key2}
                                onChange={(e) => setKey2(e.target.value)}
                              />
                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputBatch"
                                className="form-label"
                              >
                                Value
                              </label>
                              <input
                                type="text"
                                className="form-control text-black"
                                id="exampleInputBatch"
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                              />
                            </div>
                            <button
                              type="button"
                              className="btn btn-primary bg-primary text-white w-100"
                              onClick={saveChanges}
                            >
                              Submit
                            </button>
                          </form>
                        </Modal.Body>
                      </Modal>

                      <Modal show={delShow} onHide={handleClose} centered>
                        <Modal.Header closeButton>
                          <Modal.Title>Confirm Delete</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <form>
                            <div className="mb-3 d-flex justify-content-center align-items-center flex-column">
                              <h5>Are You Sure you Want To Delete ?</h5>
                              <h6 className="fs-5 text-danger">{key1}</h6>
                            </div>
                            <button
                              type="submit"
                              className="btn btn-primary bg-primary text-white w-100"
                              onClick={delEnvs}
                            >
                              Delete
                            </button>
                          </form>
                        </Modal.Body>
                      </Modal>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      </form>
    </>
  );
};

export default EnvVar;
