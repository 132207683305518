import React, { useState } from "react";
import Pagination from "./Pagination";
import { Link } from "react-router-dom";

const CourseTopic = (props) => {
  const [acPage, setAcPage] = useState(1);
  const pageSize = 10;
  const pageNumber = acPage;
  const skipItems = (pageNumber - 1) * pageSize;
  return (
    <>
      <div className="sidebar-page-container">
        <div
          className="patern-layer-one paroller"
          data-paroller-factor="0.40"
          data-paroller-factor-lg="0.20"
          data-paroller-type="foreground"
          data-paroller-direction="vertical"
          style={{ backgroundImage: "url(/images/icons/icon-1.png)" }}
        />
        <div
          className="patern-layer-two paroller"
          data-paroller-factor="0.40"
          data-paroller-factor-lg="-0.20"
          data-paroller-type="foreground"
          data-paroller-direction="vertical"
          style={{ backgroundImage: "url(/images/icons/icon-2.png)" }}
        />
        <div className="circle-one" />
        <div className="circle-two" />
        <div className="auto-container">
          <div className="row clearfix">
            {/* Content Side */}
            <div className="content-side col-lg-12 col-md-12 col-sm-12">
              <div className="our-courses">
                {/* Options View */}
                <div className="options-view">
                  <div className="clearfix">
                    {props.co.length > 0 ? (
                      <>
                        <div className="pull-left">
                          <h3 className="text-capitalize">All Topics</h3>
                        </div>
                        <div className="pull-right clearfix">
                          {/* List View */}
                          <ul className="list-view">
                            <li className="active">
                              <a>
                                <span className="icon flaticon-grid" />
                              </a>
                            </li>
                            <li>
                              <a>
                                <span className="icon flaticon-list-1" />
                              </a>
                            </li>
                          </ul>
                          {/* Type Form */}
                          <div className="type-form">
                            <form method="post" action="index.html">
                              {/* Form Group */}
                              <div className="form-group">
                                <select className="custom-select-box">
                                  <option>Newest</option>
                                  <option>Old</option>
                                </select>
                              </div>
                            </form>
                          </div>
                        </div>
                      </>
                    ) : (
                      <h1 className="text-center"> No Courses Found...</h1>
                    )}
                  </div>
                </div>
                {props?.co?.length > 0 && (
                  <div className="row clearfix">
                    <div className="accordion" id="accordionExample">
                      {props?.co
                        .slice(skipItems, skipItems + pageSize)
                        .map((datas, index) => {
                          const adjustedIndex = index + skipItems;
                          return (
                            <div
                              className="accordion-item border border-black mb-2 shadow-sm"
                              key={adjustedIndex}
                            >
                              <h2
                                className="accordion-header d-flex justify-content-between"
                                id={`heading${index}`}
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#collapse${index}`}
                                  aria-expanded="false"
                                  aria-controls={`collapse${index}`}
                                >
                                  <div className="d-md-flex justify-content-between w-100">
                                    <p className="fw-semibold mb-0">
                                      {datas.title}
                                    </p>
                                    <p className="me-3 mb-0 fw-semibold">
                                      {datas.videos.length} Items
                                    </p>
                                  </div>
                                </button>
                              </h2>
                              <div
                                id={`collapse${index}`}
                                className="accordion-collapse collapse"
                                aria-labelledby={`heading${index}`}
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  <div className="row clearfix">
                                    {datas.videos.map((datas1, index1) => {
                                      function getExtension(filename) {
                                        let a = filename.split(".").pop();
                                        let b = a.toLowerCase();
                                        return b;
                                      }
                                      const handleDownload = async (
                                        url1,
                                        title1
                                      ) => {
                                        const response = await fetch(url1);
                                        const data = await response.text();
                                        const newWindow = window.open(
                                          "",
                                          "_blank"
                                        );
                                        newWindow.document.write(data);
                                        newWindow.document.close();
                                      };
                                      return (
                                        <div
                                          className="institute-block-two col-lg-3 col-md-4 col-sm-6"
                                          key={index1}
                                        >
                                          {getExtension(datas1.url) !== "ws" ? (
                                            <Link
                                              to={`/watch/${props.cid}|${props.tid}|${adjustedIndex}|${index1}`}
                                              target="_blank"
                                            >
                                              <div className="inner-box shadow-lg w-100 h-100 d-flex flex-column justify-content-between">
                                                <div className="image">
                                                  <img
                                                    className="img-fluid"
                                                    src={
                                                      getExtension(
                                                        datas1.url
                                                      ) === "pdf"
                                                        ? "/images/pdf.jpeg"
                                                        : getExtension(
                                                            datas1.url
                                                          ) === "ws"
                                                        ? "/images/notes.png"
                                                        : "/images/topics.jpg"
                                                    }
                                                    alt="pic"
                                                  />
                                                </div>
                                                <div className="lower-content text-center">
                                                  <div className="clearfix">
                                                    <div className="pull-left">
                                                      <h6 className="fw-medium text-black">
                                                        {datas1.title}
                                                      </h6>
                                                    </div>
                                                    <div className="pull-right"></div>
                                                  </div>
                                                  <span className="theme-btn btn-style-seven mt-2">
                                                    <span className="txt">
                                                      {getExtension(
                                                        datas1.url
                                                      ) === "pdf"
                                                        ? "Get Pdf"
                                                        : getExtension(
                                                            datas1.url
                                                          ) === "ws"
                                                        ? "Get Notes"
                                                        : "Play Video"}
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                            </Link>
                                          ) : (
                                            <Link
                                              // onClick={() =>
                                              //   handleDownload(
                                              //     datas1.url,
                                              //     datas1.title
                                              //   )
                                              // }
                                              to={`/watch/${props.cid}|${props.tid}|${adjustedIndex}|${index1}`}
                                              target="_blank"
                                            >
                                              <div className="inner-box shadow-lg w-100 h-100 d-flex flex-column justify-content-between">
                                                <div className="image">
                                                  <img
                                                    className="img-fluid"
                                                    src={
                                                      getExtension(
                                                        datas1.url
                                                      ) === "pdf"
                                                        ? "/images/pdf.jpeg"
                                                        : getExtension(
                                                            datas1.url
                                                          ) === "ws"
                                                        ? "/images/notes.png"
                                                        : "/images/topics.jpg"
                                                    }
                                                    alt="pic"
                                                  />
                                                </div>
                                                <div className="lower-content text-center">
                                                  <div className="clearfix">
                                                    <div className="pull-left">
                                                      <h6 className="fw-medium text-black">
                                                        {datas1.title}
                                                      </h6>
                                                    </div>
                                                    <div className="pull-right"></div>
                                                  </div>
                                                  <span className="theme-btn btn-style-seven mt-2">
                                                    <span className="txt">
                                                      {getExtension(
                                                        datas1.url
                                                      ) === "pdf"
                                                        ? "Get Pdf"
                                                        : getExtension(
                                                            datas1.url
                                                          ) === "ws"
                                                        ? "Get Notes"
                                                        : "Play Video"}
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                              {/* </a> */}
                                            </Link>
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* Post Share Options */}
          {props.co.length > 0 && (
            <Pagination
              total={props.co.length}
              limit={10}
              page={acPage}
              setCurrentPage={setAcPage}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CourseTopic;
