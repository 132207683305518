import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import Coaching from "./pages/Coaching";
import CoachingCourse from "./pages/CoachingCourse";
import CourseTopics from "./pages/CourseTopics";
import ComboTopics from "./pages/ComboTopics";
import Admin from "./pages/Admin";
import Login from "./components/admin/Login";
import Content from "./components/admin/Content";
import Meow2 from "./pages/Meow2";
import AddCoachings from "./pages/AddCoachings";
import ManCoachings from "./pages/ManCoachings";
import Meow3 from "./pages/Meow3";
import Meow4 from "./pages/Meow4";
import EnvVar from "./pages/EnvVar";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/coaching",
    element: <Coaching />,
  },
  {
    path: "/admin",
    element: <Admin />,
    children: [
      {
        path: "",
        element: <Content />,
      },
      {
        path: "add_coachings",
        element: <AddCoachings />,
      },
      {
        path: "manage_coachings",
        element: <ManCoachings />,
      },
      {
        path: "env_var",
        element: <EnvVar />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/coaching/:id",
    element: <CoachingCourse />,
  },
  {
    path: "/course/:cid/:tid",
    element: <CourseTopics />,
  },
  {
    path: "/combo/:cid/:tid",
    element: <ComboTopics />,
  },
  {
    path: "/watch/:id",
    element: <Meow3 />,
  },
  {
    path: "/meow4",
    element: <Meow4 />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <RouterProvider router={router} />
  // </React.StrictMode>
);
