import React, { useEffect } from "react";
import Nav from "../components/Nav";
import TopicSection from "../components/TopicSection";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import Hometitle from "../components/Hometitle";

const Home = () => {
  useEffect(() => {
    console.log((`${process.env.REACT_APP_API_KEY}/sc`));
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div>
        <div className="page-wrapper">
          {/* Preloader */}
          <div className="preloader1" />
          {/* Main Header*/}
          <Nav />
          {/* End Main Header */}
          <Hometitle showBtn />
          {/* Topics Courses */}
          <TopicSection />
          {/* End Topics Courses */}
          {/* Popular Courses */}
          {/* <PopularSection /> */}
          {/* End Popular Courses */}
          {/* Call To Action Section Two */}
          <CallToAction />
          {/* End Call To Action Section Two */}
          {/*Main Footer*/}
          <Footer />
        </div>
        {/*End pagewrapper*/}
        {/*Scroll to top*/}
        <ScrollToTop />
      </div>
    </>
  );
};

export default Home;
